exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-uiux-js": () => import("./../../../src/pages/courses/uiux.js" /* webpackChunkName: "component---src-pages-courses-uiux-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shots-js": () => import("./../../../src/pages/shots.js" /* webpackChunkName: "component---src-pages-shots-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article_post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-course-post-js": () => import("./../../../src/templates/course_post.js" /* webpackChunkName: "component---src-templates-course-post-js" */),
  "component---src-templates-shot-post-js": () => import("./../../../src/templates/shot_post.js" /* webpackChunkName: "component---src-templates-shot-post-js" */)
}

